import {Routes, RouterModule} from '@angular/router';

import { EventsDashboardComponent } from './events-dashboard.component';
import { EventsRegistrations } from './events-registrations/events-registrations.component';
import { EventsRegistrationsSubscriptionComponent } from './events-registrations-subscription/events-registrations-subscription.component';
import { EventsRegistrationSubscriptionAddComponent } from './events-registrations-subscription/events-registration-subscription-add/events-registration-subscription-add.component';
import { EventsRegistrationSubscriptionEditComponent } from './events-registrations-subscription/events-registration-subscription-edit/events-registration-subscription-edit.component';
import { EventsRegistrationSubscriptionDelComponent } from './events-registrations-subscription/events-registration-subscription-del/events-registration-subscription-del.component';
import { AuthGuardService } from '../services/auth.guard.service';
import { EventsRegistrationSubscriptionGroupComponent } from './events-registrations-subscription/events-registration-subscription-group/events-registration-subscription-group.component';
import { EventsRegistrationSubscriptionGroupEditTNComponent } from './events-registrations-subscription/events-registration-subscription-group/events-registration-subscription-group-edit-tn/events-registration-subscription-group-edit-tn.component';
import { EventsRegistrationSubscriptionGroupDelTNComponent } from './events-registrations-subscription/events-registration-subscription-group/events-registration-subscription-group-del-tn/events-registration-subscription-group-del-tn.component';
import { EventsRegistrationSubscriptionGroupAddTNComponent } from './events-registrations-subscription/events-registration-subscription-group/events-registration-subscription-group-add-tn/events-registration-subscription-group-add-tn.component';

export const eventsRoutes: Routes = [
  { path: '', component: EventsDashboardComponent},
  { path: ':event_id', redirectTo:':event_id/registrations', pathMatch: 'full'},
  { path: ':event_id/registrations', component: EventsRegistrations, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription', component: EventsRegistrationsSubscriptionComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/add', component: EventsRegistrationSubscriptionAddComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/edit', component: EventsRegistrationSubscriptionEditComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/del', component: EventsRegistrationSubscriptionDelComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/group', component: EventsRegistrationSubscriptionGroupComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/group/addTN', component: EventsRegistrationSubscriptionGroupAddTNComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/group/editTN/:tn_id', component: EventsRegistrationSubscriptionGroupEditTNComponent, canActivate: [AuthGuardService]},
  { path: ':event_id/registrations/:registration_id/subscription/group/delTN/:tn_id', component: EventsRegistrationSubscriptionGroupDelTNComponent, canActivate: [AuthGuardService]},

];
