<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">Aktualisiere: {{registration.title}}</h1>
  </div>
</div>
<div class="col-md-10 col-md-offset-1">
  <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
  <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
  <div class="well well bs-component">
    <form novalidate class="form-horizontal" (ngSubmit)="updateRegistration(registration)" #editRegistrationForm="ngForm">
      <fieldset>
        <legend></legend>
        <div class="form-group">
          <label for="title" class="col-lg-2 control-label">Anmeldungsname</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" id="title" name="title" placeholder="Anmeldungsname" [(ngModel)]="registration.title" #title="ngModel" required>
            <div *ngIf="title.errors?.required && title.dirty" class="alert alert-danger">
              Anmeldungsname ist ein Pflichtfeld
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-2 control-label">Beschreibung</label>
          <div class="col-lg-10">
            <textarea class="form-control" rows="3" id="description" name="description" [(ngModel)]="registration.description" #description="ngModel"></textarea>
          </div>
          <div class="alert alert-light">
            Diese Beschreibung wird oberhalb des Anmeldeformulars angezeigt.
          </div>
        </div>
        <div class="form-group">
          <label for="template" class="col-lg-2 control-label">Vorlage</label>
          <!--TODO: STANDARDWERT SETZEN-->
          <div class="col-lg-10">
            <select class="form-control" id="template" name="template" [(ngModel)]="registration.template" #template="ngModel" disabled>
              <option *ngFor="let template of templates | async" [selected]="registration.template == template.id" [value]="template.id">{{template.title}}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-2 control-label">Status</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="active" name="active" [(ngModel)]="registration.active" #active="ngModel">
              <option [selected]="registration.active == 1" [value]="1">Aktiv</option>
              <option [selected]="registration.active == 0" [value]="0">Inaktiv</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-2 control-label">Anmeldeschluss</label>
          <div class="col-lg-10">
            <input type="date" class="form-control" id="closingDate" name="closingDate" placeholder="Anmeldeschluss" [(ngModel)]="registration.closingDate" #closingDate="ngModel" required>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmation" class="col-lg-10 control-label">Manuelle Anmeldebestätigung erforderlich?</label>
          <div class="col-lg-10">
            <select class="form-control" rows="2" id="confirmation" name="confirmation" [(ngModel)]="registration.confirmation" #confirmation="ngModel" required disabled>
              <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
              <option [value]="1">Ja</option>
              <option [value]="0">Nein</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="description" class="col-lg-10 control-label">Maximale Teilnehmendenzahl</label>
          <div class="col-lg-10">
            <input type="number" class="form-control" id="maxTNs" name="maxTNs" placeholder="Maximale Teilnehmendenzahl" [(ngModel)]="registration.maxTNs" #maxTNs="ngModel" required>
            <div class="alert alert-light">
              Eine maximale Teilnehmendenzahl von <strong>0</strong> bedeutet keine Teilnemendenbegrenzung.
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-lg-10 col-lg-offset-2">
            <a [routerLink]="['/admin/event/', event_id, 'registrations']" class="btn btn-default">Abbrechen</a>
            <button type="submit" class="btn btn-primary" [disabled]="editRegistrationForm.invalid">Aktualisieren</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
