<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">Neuen Benutzer registrieren</h1>
  </div>
</div>
<form novalidate class="form-horizontal" (ngSubmit)="createUser(createUserForm.value)" #createUserForm="ngForm">
  <div class="col-md-10 col-md-offset-1">
    <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
    <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
    <div class="card my-2">
      <div class="card-header">Persönliche Daten</div>
      <div class="card-body">
        <div class="mx-auto">
          <fieldset>
            <div class="form-group">
              <label for="surname" class="col-lg-3 control-label">Vorname<span class="bdkj-required">*</span></label>
              <div class="col-lg-10">
                <input type="text" class="form-control" id="surname" name="surname" placeholder="Vorname" ngModel #surname="ngModel" required>
                <div *ngIf="surname.errors?.required && surname.dirty" class="alert alert-danger">
                  Vorname ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="givenname" class="col-lg-3 control-label">Nachname<span class="bdkj-required">*</span></label>
              <div class="col-lg-10">
                <input type="text" class="form-control" id="givenname" name="givenname" placeholder="Nachname" ngModel #givenname="ngModel" required>
                <div *ngIf="givenname.errors?.required && givenname.dirty" class="alert alert-danger">
                  Nachname ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="email" class="col-lg-3 control-label">E-Mail-Adresse<span class="bdkj-required">*</span></label>
              <div class="col-lg-10">
                <input type="email" class="form-control" id="email" name="email" placeholder="E-Mail-Adresse" ngModel #email="ngModel" email required>
                <div *ngIf="email.errors?.required && email.dirty" class="alert alert-danger">
                  E-Mail-Adresse ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <hr />
            <div class="form-group">
              <label for="password" class="col-lg-3 control-label">Passwort<span class="bdkj-required">*</span></label>
              <div class="col-lg-10">
                <input type="password" class="form-control" id="password" name="password" placeholder="Passwort" ngModel #password="ngModel" minlength=6 required>
                <div *ngIf="password.errors?.required && password.dirty || password.errors?.minlength && password.dirty" class="alert alert-danger">
                  Das Passwort muss mindestens 6 Zeichen lang sein.
    
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="password_confirmation" class="col-lg-3 control-label">Passwort wiederholen<span class="bdkj-required">*</span></label>
              <div class="col-lg-10">
                <input type="password" class="form-control" id="password_confirmation" name="password_confirmation" placeholder="Passwort wiederholen" ngModel #password_confirmation="ngModel" required>
                <div *ngIf="password_confirmation.errors?.required && password_confirmation.dirty" class="alert alert-danger">
                  Passwort wiederholen ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <hr />
            <div class="form-group">
              <label for="verband" class="col-lg-3 control-label">Verband<span class="bdkj-required">*</span></label>
              <div class="col-lg-10">
                <select class="form-control" rows="2" id="verband" name="verband" [ngModel]=null ngModel #verband="ngModel" required>
                  <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
                  <option *ngFor="let verband of verbaende | async" [value]="verband.id">{{verband.title}}</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="gruppierung" class="col-lg-3 control-label">Gruppierung</label>
              <div class="col-lg-10">
                <input type="gruppierung" class="form-control" id="gruppierung" name="gruppierung" placeholder="Gruppierung" ngModel #gruppierung="ngModel">
              </div>
            </div>
          </fieldset>
          </div>

      </div>
    </div>
    <div class="card my-2">
      <div class="card-header">Weitere Angaben</div>
      <div class="card-body">
        <div class="mx-auto">
          <fieldset>
            <div class="form-group">
              <label for="surname" class="col-lg-3 control-label">Geschlecht</label>
              <div class="col-lg-10">
                <select class="form-control" rows="2" id="gender" name="gender" [ngModel]=null ngModel #gender="ngModel">
                  <option [value]="null" [selected] disabled hidden>Bitte auswählen</option>
                  <option value="d">divers</option>
                  <option value="w">weiblich</option>
                  <option value="m">männlich</option>
                </select>
                <div *ngIf="gender.errors?.required && gender.dirty" class="alert alert-danger">
                  Geschlecht ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="strasse" class="col-lg-3 control-label">Strasse</label>
              <div class="col-lg-10">
                <input type="strasse" class="form-control" id="strasse" name="strasse" placeholder="Strasse" ngModel #strasse="ngModel">
                <div *ngIf="strasse.errors?.required && strasse.dirty" class="alert alert-danger">
                  Strasse ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="hausnummer" class="col-lg-3 control-label">Hausnummer</label>
              <div class="col-lg-10">
                <input type="hausnummer" class="form-control" id="hausnummer" name="hausnummer" placeholder="Hausnummer" ngModel #hausnummer="ngModel">
                <div *ngIf="hausnummer.errors?.required && hausnummer.dirty" class="alert alert-danger">
                  Hausnummer ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="plz" class="col-lg-3 control-label">PLZ</label>
              <div class="col-lg-10">
                <input type="plz" class="form-control" id="plz" name="plz" placeholder="PLZ" ngModel #plz="ngModel">
                <div *ngIf="plz.errors?.required && plz.dirty" class="alert alert-danger">
                  PLZ ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="ort" class="col-lg-3 control-label">Ort</label>
              <div class="col-lg-10">
                <input type="ort" class="form-control" id="ort" name="ort" placeholder="Ort" ngModel #ort="ngModel">
                <div *ngIf="ort.errors?.required && ort.dirty" class="alert alert-danger">
                  Ort ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="telefonnummer" class="col-lg-3 control-label">Telefonnummer</label>
              <div class="col-lg-10">
                <input type="telefonnummer" class="form-control" id="telefonnummer" name="telefonnummer" placeholder="Telefonnummer" ngModel #telefonnummer="ngModel">
                <div *ngIf="telefonnummer.errors?.required && telefonnummer.dirty" class="alert alert-danger">
                  Telefonnummer ist ein Pflichtfeld
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="geburtsdatum" class="col-lg-10 control-label">Geburtsdatum</label>
              <div class="col-lg-10">
                <input type="date" class="form-control" id="geburtsdatum" name="geburtsdatum" placeholder="Geburtsdatum" ngModel #geburtsdatum="ngModel">
                <div *ngIf="geburtsdatum.errors?.required && geburtsdatum.dirty" class="alert alert-danger">
                  Geburtsdatum ist ein Pflichtfeld
                </div>
              </div>
            </div>
          </fieldset>
          </div>

      </div>
    </div>
  </div>       
      
        <div class="form-group">
          <div class="col-lg-10 col-lg-offset-2">
            <a [routerLink]="['/login']" class="btn btn-outline-secondary col-12 col-sm-3">Abbrechen</a>
            <button type="submit" class="btn btn-primary col-12 col-sm-3 mx-sm-2" [disabled]="createUserForm.invalid">Registrieren</button>
          </div>
        </div>
    </form>