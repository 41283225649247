import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TemplateFieldService} from '../../../../../services/template-field.service';
import { TemplateField } from '../../../../../models/templateField';
import { Template } from '../../../../../models/template';
import {TemplateService} from '../../../../../services/template.service';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-admin-template-fields-edit',
  templateUrl: './admin-template-fields-edit.component.html',
  styleUrls: ['./admin-template-fields-edit.component.css']
})
export class AdminTemplateFieldsEditComponent implements OnInit {

  params: any;
  template_id: any;
  templateSection_id: any;
  templateField_id: any;
  templateField: any;
  status: any;
  message: any;
  templates:  Observable<Template[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private templateFieldService: TemplateFieldService,
    private templateService: TemplateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => {
      this.templateField_id = params['id'];
      this.templateSection_id = params['section_id'];
      this.template_id = params['template_id'];
    });
    this.templates = this.templateService.getTemplates();
   this.templateField = this.templateFieldService.getTemplateField(this.templateField_id, this.templateSection_id).subscribe(
     data => {
       console.log(data)
       this.templateField.description = data['description'];
       this.templateField.title = data['title'];
       this.templateField.type = data['type'];
       this.templateField.id = data['id'];
       this.templateField.choices = data['choices'];
       this.templateField.required = data['required'];
      },
     error => console.log(<any>error));
  }

  ngOnDestroy() {
   this.params.unsubscribe();
 }

 updateTemplateField(templateField) {
   templateField.section = this.templateSection_id
   this.templateFieldService.updateTemplateField(this.templateSection_id, templateField)
   .subscribe(
     templateField => {
       this.status = 'success';
       this.message = templateField['message'];
       this.router.navigate(['/admin/template/', this.template_id]);
     },
     error => {
       console.log(<any>error);
       this.status = 'error';
       this.message = error['message'];
     });
   }

}
