import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TemplateSectionService} from '../../../../services/template-section.service';
import { TemplateSection } from '../../../../models/templateSection';
import { Template } from '../../../../models/template';
import {TemplateService} from '../../../../services/template.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-template-section-edit',
  templateUrl: './admin-template-section-edit.component.html',
  styleUrls: ['./admin-template-section-edit.component.css']
})
export class AdminTemplateSectionEditComponent implements OnInit {

  params: any;
  template_id: any;
  templateSection_id: any;
  templateSection: any;
  templateSections: Observable<TemplateSection[]>;
  status: any;
  message: any;
  templates:  Observable<Template[]>;


  constructor(
    private activatedRoute: ActivatedRoute,
    private templateSectionService: TemplateSectionService,
    private templateService: TemplateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.template_id = params['template_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.templateSection_id = params['id']);
    this.templates = this.templateService.getTemplates();
    this.templateSections = this.templateSectionService.getTemplateSections(this.template_id);
   this.templateSection = this.templateSectionService.getTemplateSection(this.templateSection_id, this.template_id).subscribe(
     data => {
       this.templateSection.description = data['description'];
       this.templateSection.title = data['title'];
       this.templateSection.maxNumber = data['maxNumber'];
       this.templateSection.id = data['id'];
       this.templateSection.active = data['active'];
       this.templateSection.type = data['type'];
       this.templateSection.template = data['template'];

       if (this.templateSection.mainTemplateSection == null)
       {
         this.templateSection.mainTemplateSection = 0;
       }
     },

     error => console.log(<any>error));
  }

  ngOnDestroy() {
   this.params.unsubscribe();
 }

 updateTemplateSection(templateSection) {
   this.templateSectionService.updateTemplateSection(templateSection, this.template_id)
   .subscribe(
     templateSection => {
       this.status = 'success';
       this.message = templateSection['message'];
       this.router.navigate(['/admin/template/', this.template_id]);
     },
     error => {
       console.log(<any>error);
       this.status = 'error';
       this.message = error['message'];
     });
   }

}
