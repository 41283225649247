import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RouterTestingModule } from '@angular/router/testing';

import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { AdminEventCreateComponent } from './admin/events/admin-event-create/admin-event-create.component';
import { AdminEventDeleteComponent } from './admin/events/admin-event-delete/admin-event-delete.component';
import { AdminEventEditComponent } from './admin/events/admin-event-edit/admin-event-edit.component';
import { AdminEventListComponent } from './admin/events/admin-event-list/admin-event-list.component';
import { AdminEventRegistrationListComponent } from './admin/events/admin-event-registration-list/admin-event-registration-list.component';
import { AdminEventRegistrationCreateComponent } from './admin/events/admin-event-registration-create/admin-event-registration-create.component';
import { AdminEventRegistrationDeleteComponent } from './admin/events/admin-event-registration-delete/admin-event-registration-delete.component';
import { AdminEventRegistrationEditComponent } from './admin/events/admin-event-registration-edit/admin-event-registration-edit.component';
import { AdminUserEditComponent } from './admin/users/admin-user-edit/admin-user-edit.component';
import { AdminUserListComponent } from './admin/users/admin-user-list/admin-user-list.component';
import { AdminUserCreateComponent } from './admin/users/admin-user-create/admin-user-create.component';
import { AdminUserDeleteComponent } from './admin/users/admin-user-delete/admin-user-delete.component';
import { AdminTemplatesDeleteComponent } from './admin/templates/admin-templates-delete/admin-templates-delete.component';
import { AdminTemplatesEditComponent } from './admin/templates/admin-templates-edit/admin-templates-edit.component';
import { AdminTemplatesCreateComponent } from './admin/templates/admin-templates-create/admin-templates-create.component';
import { AdminTemplatesListComponent } from './admin/templates/admin-templates-list/admin-templates-list.component';
import { LoginComponent } from './login/login.component';
import { EventsComponent } from './events/events.component';
import { EventsRegistrations } from './events/events-registrations/events-registrations.component';
import { EventsDashboardComponent } from './events/events-dashboard.component';
import { AdminDashboardComponent } from './admin/admin-dashboard.component';

import {adminRoutes} from './admin/admin.routes';
import {eventsRoutes} from './events/events.routes';
import {AppRoutingModule} from './app.routes';
import { NavbarComponent } from './navbar.component';
import { FooterComponent } from './footer.component';
import { BackendService } from './services/backend.service';
import { EventService } from './services/event.service';
import { TemplateService } from './services/template.service';
import { TemplateSectionService } from './services/template-section.service';
import { TemplateFieldService } from './services/template-field.service';
import { RegistrationService } from './services/registration.service';
import { RegistrationTypeService } from './services/registration-type.service';
import { UserService } from './services/user.service';
import { AuthGuardService } from './services/auth.guard.service';
import { StammService } from './services/stamm.service';
import { SubscriptionService } from './services/subscription.service';
import { VerbandService } from './services/verband.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminTemplateDashboardComponent } from './admin/templates/admin-template-dashboard/admin-template-dashboard.component';
import { AdminTemplateFieldsComponent } from './admin/templates/admin-template-dashboard/admin-template-fields.component';
import { AdminTemplateCopyComponent } from './admin/templates/admin-template-copy/admin-template-copy.component';
import { AdminTemplateSectionCreateComponent } from './admin/templates/section/admin-template-section-create/admin-template-section-create.component';
import { AdminTemplateSectionDeleteComponent } from './admin/templates/section/admin-template-section-delete/admin-template-section-delete.component';
import { AdminTemplateSectionEditComponent } from './admin/templates/section/admin-template-section-edit/admin-template-section-edit.component';
import { AdminTemplateFieldsEditComponent } from './admin/templates/section/fields/admin-template-fields-edit/admin-template-fields-edit.component';
import { AdminTemplateFieldsDeleteComponent } from './admin/templates/section/fields/admin-template-fields-delete/admin-template-fields-delete.component';
import { AdminTemplateFieldsCreateComponent } from './admin/templates/section/fields/admin-template-fields-create/admin-template-fields-create.component';
import { ChangeDataComponent } from './user/change-data/change-data.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { RegisterComponent } from './register/register.component';
import { EventsRegistrationsSubscriptionComponent } from './events/events-registrations-subscription/events-registrations-subscription.component';
import { AdminUserResetComponent } from './admin/users/admin-user-reset/admin-user-reset.component';
import { AdminEventRegistrationSubscriptionsListComponent } from './admin/events/admin-event-registration-subscriptions-list/admin-event-registration-subscriptions-list.component';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import{NotifyService} from './services/notify.service';
import { FilterPipe } from './pipes/filter.pipe';
import { ChartsModule } from 'ng2-charts';
import { Settings } from './classes/settings';
import { JwtModule } from '@auth0/angular-jwt';
import { EventsRegistrationSubscriptionAddComponent } from './events/events-registrations-subscription/events-registration-subscription-add/events-registration-subscription-add.component';
import { EventsRegistrationSubscriptionEditComponent } from './events/events-registrations-subscription/events-registration-subscription-edit/events-registration-subscription-edit.component';
import { EventsRegistrationSubscriptionDelComponent } from './events/events-registrations-subscription/events-registration-subscription-del/events-registration-subscription-del.component';
import { EventsRegistrationSubscriptionGroupComponent } from './events/events-registrations-subscription/events-registration-subscription-group/events-registration-subscription-group.component';
import { EventsRegistrationSubscriptionGroupAddTNComponent } from './events/events-registrations-subscription/events-registration-subscription-group/events-registration-subscription-group-add-tn/events-registration-subscription-group-add-tn.component';
import { EventsRegistrationSubscriptionGroupEditTNComponent } from './events/events-registrations-subscription/events-registration-subscription-group/events-registration-subscription-group-edit-tn/events-registration-subscription-group-edit-tn.component';
import { EventsRegistrationSubscriptionGroupDelTNComponent } from './events/events-registrations-subscription/events-registration-subscription-group/events-registration-subscription-group-del-tn/events-registration-subscription-group-del-tn.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';


export function tokenGetter() {
  return localStorage.getItem(Settings.authAccessTokenName)
}


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AdminEventCreateComponent,
    AdminEventDeleteComponent,
    AdminEventEditComponent,
    AdminEventListComponent,
    AdminEventRegistrationListComponent,
    AdminEventRegistrationCreateComponent,
    AdminEventRegistrationDeleteComponent,
    AdminEventRegistrationEditComponent,
    AdminEventRegistrationSubscriptionsListComponent,
    AdminUserEditComponent,
    AdminUserListComponent,
    AdminUserCreateComponent,
    AdminUserDeleteComponent,
    AdminTemplatesDeleteComponent,
    AdminTemplatesEditComponent,
    AdminTemplatesCreateComponent,
    AdminTemplatesListComponent,
    LoginComponent,
    EventsComponent,
    EventsRegistrations,
    EventsRegistrationsSubscriptionComponent,
    EventsDashboardComponent,
    AdminDashboardComponent,
    NavbarComponent,
    FooterComponent,
    AdminTemplateDashboardComponent,
    AdminTemplateCopyComponent,
    AdminTemplateFieldsComponent,
    AdminTemplateSectionCreateComponent,
    AdminTemplateSectionDeleteComponent,
    AdminTemplateSectionEditComponent,
    AdminTemplateFieldsEditComponent,
    AdminTemplateFieldsDeleteComponent,
    AdminTemplateFieldsCreateComponent,
    RegisterComponent,
    AdminUserResetComponent,
    ChangeDataComponent,
    FilterPipe,
    EventsRegistrationSubscriptionAddComponent,
    EventsRegistrationSubscriptionEditComponent,
    EventsRegistrationSubscriptionDelComponent,
    ChangePasswordComponent,
    EventsRegistrationSubscriptionGroupComponent,
    EventsRegistrationSubscriptionGroupAddTNComponent,
    EventsRegistrationSubscriptionGroupEditTNComponent,
    EventsRegistrationSubscriptionGroupDelTNComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SnotifyModule,
    ChartsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['homestead.test']
      }
    })
  ],
  providers: [
    AuthGuardService,
    BackendService,
    VerbandService,
    EventService,
    NotifyService,
    RegistrationService,
    RegistrationTypeService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
    StammService,
    SubscriptionService,
    TemplateService,
    TemplateSectionService,
    TemplateFieldService,
    UserService],
  bootstrap: [AppComponent]
})
export class AppModule { }
