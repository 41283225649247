import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import { Event } from '../../../models/event';
import { EventService } from '../../../services/event.service';
import { Settings } from '../../../classes/settings';


@Component({
  selector: 'app-admin-event-list',
  templateUrl: './admin-event-list.component.html',
  styleUrls: ['./admin-event-list.component.css']
})
export class AdminEventListComponent implements OnInit {
events: Observable<Event[]>;
backendurl: any;
filter: any;

  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.backendurl = Settings.appURL
    this.events = this.eventService.getActiveEvents();
  }

  loadAll(evt) {
    if (evt.target.checked) {
      this.events = this.eventService.getEvents()
    }
    else {
      this.events = this.eventService.getActiveEvents()
    }
  }


}
