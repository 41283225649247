<div class="col-md-12 col-md-offset-1">
  <div class="alert alert-secondary mt-3 text-center" role="alert">
    <h6>Du bist zum ersten Mal hier?</h6> <p>Dann registriere dich bitte zunächst und logge dich anschließend in deinen Account ein. Danach kannst du dich für die hier aufgeführten Veranstaltungen an- und abmelden.</p>
    <h6>Du hast diese Seite schon einmal für eine Anmeldung, z.B eine andere Veranstaltung benutzt?</h6>
    <p>Dann logge dich zunächst in dein Account ein und melde dich dann für die gewünschte Veranstaltung an.</p>
  </div>
  <div class="row row-cols-1 row-cols-md-3 justify-content-md-center">
    <div class="col mb-4" *ngFor="let event of events | async" >
      <div class="card h-100">
        <p><img class="card-img-top" src="{{backendurl}}images/{{event.imageLink}}" alt="{{event.title}}"></p>
        <div class="card-body">
          <p [innerHTML]="event.description" class="card-text"></p>
        </div>
        <div class="card-footer">
          <h5 class="card-title">{{event.title}} <span *ngIf="event.multipleRegistrations!=true && event.free != 'no matter'" class="badge badge-bdkj">{{event.free}} freie Plätze</span></h5>
          <a [routerLink]="[event.id, 'registrations']" class="btn btn-primary col-12"> Anmeldung</a>
        </div>
      </div>
    </div>
</div>

</div>
