import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RegistrationService} from '../../../services/registration.service';
import { Registration } from '../../../models/registration';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-event-registration-delete',
  templateUrl: './admin-event-registration-delete.component.html',
  styleUrls: ['./admin-event-registration-delete.component.css']
})
export class AdminEventRegistrationDeleteComponent implements OnInit {

  id: any;
  event_id: any;
  params: any;

  constructor (private activatedRoute: ActivatedRoute, private registrationService: RegistrationService, private router: Router) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.id = params['id']);
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
      this.registrationService.deleteRegistration(this.event_id, this.id).subscribe(
        data => {
          this.router.navigate(['/admin/event/', this.event_id, 'registrations']);
        },
        error => console.log(<any>error));
      }

      ngOnDestroy() {
        this.params.unsubscribe();
      }
}
