<div class="jumbotron row">
  <div class="display-4 col-12 col-sm-9">
      <h1 class="display-4">{{registration.title | uppercase}}</h1>
      <h2 class="display-6">{{event.title | uppercase}}</h2>
    </div>
    <div class="col-12 col-sm-3">
      <img class="img-fluid" src="{{backendurl}}images/{{event.imageLink}}" alt="">
    </div>  </div>
    <div class="alert alert-warning text-center" role="alert" *ngIf="closed == 1">
      Eine Anmeldung ist leider nicht mehr möglich, weil der Anmeldeschluss erreicht wurde.
    </div>
  <form novalidate class="form-horizontal" (ngSubmit)="AddOrUpdateSubscription(AddOrUpdateSubscriptionForm.value)" #AddOrUpdateSubscriptionForm="ngForm" >
    <div *ngIf="registration.description" [innerHTML]="registration.description" class="alert alert-info">
    </div>
    <fieldset [disabled]="closed == 1">
    <div class="col-md-10 col-md-offset-1">
        <div class="card my-2" *ngFor="let section of sections">
          <div class="card-header">{{section.title}}</div>
          <div class="card-body">
            <div class="mx-auto" *ngFor="let templateField of templateFields[section.id]; let last = last">
              <div class="form-group">
              <label for="{{templateField.title}}" class="col-lg-6 control-label">{{templateField.title}}<span *ngIf="templateField.required == 1" class="bdkj-required">*</span></label>
              <div *ngIf="templateField.type == 'text'" class="col-lg-6">
                <input min="0" type="text" class="form-control" id="{{section?.id}}_{{templateField?.id}}" name="{{section?.id}}_{{templateField?.id}}" 
                  placeholder="{{templateField.title}}" [(ngModel)]="subscription[section?.id + '_' + templateField?.id]" #{{section?.id}}_{{templateField?.id}}="ngModel" 
                  [required]="templateField.required == 1">
              </div>
              <div *ngIf="templateField.type == 'textarea'" class="col-lg-6">
                <textarea name="name" class="form-control" rows="8" cols="80" id="{{section?.id}}_{{templateField?.id}}" name="{{section?.id}}_{{templateField?.id}}" 
                  placeholder="{{templateField.title}}" [(ngModel)]="subscription[section?.id + '_' + templateField?.id]" #{{section?.id}}_{{templateField?.id}}="ngModel" 
                  [required]="templateField.required == 1"></textarea>
              </div>
              <div *ngIf="templateField.type == 'number'" class="col-lg-6">
                <input min="0" type="number" class="form-control" id="{{section?.id}}_{{templateField?.id}}" name="{{section?.id}}_{{templateField?.id}}" 
                  [(ngModel)]="subscription[section?.id + '_' + templateField?.id]" #{{section?.id}}_{{templateField?.id}}="ngModel" 
                  [required]="templateField.required == 1">
              </div>
              <div *ngIf="templateField.type == 'option'" class="col-lg-6">
                <div class="form-check" *ngFor="let choice of templateField?.choices">
                    <input min="0" type="checkbox" class="form-check-input" id="{{section?.id}}_{{templateField?.id}}" name="{{section?.id}}_{{templateField?.id}}" 
                      ngModel #{{section?.id}}_{{templateField?.id}}="ngModel" value="{{templateField.value}}" 
                      (change)="updateField(section.id, templateField.id, choice.name, $event)" [required]="templateField.required == 1">
                  <label class="form-check-label">
                    {{choice.name}}
                  </label>
                </div>
              </div>
              <div *ngIf="templateField.type == 'choice'" class="col-lg-6">
                <div class="form-check" *ngFor="let choice of templateField?.choices">
                    <input min="0" type="radio" class="form-check-input" id="{{section?.id}}_{{templateField?.id}}" name="{{section?.id}}_{{templateField?.id}}" 
                      ngModel #{{section?.id}}_{{templateField?.id}}="ngModel" value="{{choice.name}}" 
                        (change)="updateField(section.id, templateField.id, choice.name, $event)" [required]="templateField.required == 1">
                  <label class="form-check-label">
                    {{choice.name}}
                  </label>
                </div>
              </div>
              <hr *ngIf="last!=true"/>
              </div>
              </div>
  
          </div>
        </div>
      </div>
    </fieldset>
  
    <div class="form-group">
      <div class="col-lg-10 col-lg-offset-2">
        <a [routerLink]="['/events']" class="btn btn-outline-secondary col-12 col-sm-3">Abbrechen</a>
        <button type="submit" class="btn btn-primary col-12 col-sm-3 mx-sm-2" [disabled]="closed == 1 || AddOrUpdateSubscriptionForm.invalid">Speichern</button>
      </div>
    </div>
  
  </form>
  <hr />


  <div class="col-md-12 col-md-offset-1">

    <div>
      <a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription', 'group', 'addTN']" class="btn btn-success" *ngIf="event.active == 1 && closed == 0 && subscription_id != undefined">Neue/n Teilnehmende/n anlegen</a>
      <a (click)="export()" class="btn btn-default float-right" role="button" aria-expanded="false" *ngIf="subscription_id != undefined"><i class="fa fa-file-excel-o"></i> Export Excel</a>
  
    </div>
  </div>
  <br />
  <div class="col-md-12 col-md-offset-1" *ngIf="subscription_id != undefined">
    <div class="table-responsive">
      <table class="table table-users">
        <thead>
          <tr>
            <th>Nachname</th>
            <th>Vorname</th>
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tn of tns">
            <td>{{tn[fieldNachname]}}</td>
            <td>{{tn[fieldVorname]}}</td>
            <td>
             <a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription', 'group', 'editTN', tn.id]" class="btn btn-warning btn-users mx-2" *ngIf="closed == 0"> <i class="fa fa-pencil"></i></a>
             <a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription', 'group', 'delTN', tn.id]" class="btn btn-danger btn-users" *ngIf="closed == 0"> <i class="fa fa-trash"></i></a>
           </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  