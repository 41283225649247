
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TemplateField } from '../models/templateField';
import { BackendService } from '../services/backend.service';


@Injectable()
export class TemplateFieldService {

  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) { }

  addTemplateField(template_section_id: string, templateField: Object): Observable<TemplateField[]> {
    return this.backendService.post('templates/sections/'+ template_section_id + '/templateFields', templateField)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
    }

  getTemplateFields(template_section_id: string): Observable<any>{
    return this.backendService.get('templates/sections/'+ template_section_id + '/templateFields')
       .map((response) => response);
  }

  getTemplateField(template_field_id: string, template_section_id: string): Observable<TemplateField>{
    return this.backendService.get('templates/sections/' + template_section_id + '/templateFields/' + template_field_id)
       .map((response) => response);
  }

  updateTemplateField(template_section_id: string, templateField: Object): Observable<TemplateField[]> {
   const apiUrl = 'templates/sections';
   const url = `${apiUrl}/${template_section_id}/templateFields/${templateField['id']}`;
   return this.backendService.put(url, templateField)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

  deleteTemplateField(template_section_id: string, templateField: string): Observable<any> {
   const apiUrl = 'templates/sections';
   const url = `${apiUrl}/${template_section_id}/templateFields/${templateField}`;
   return this.backendService.delete(url)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }
}
