import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TemplateService} from '../../../services/template.service';
import {TemplateSectionService} from '../../../services/template-section.service';
import { TemplateSection } from '../../../models/templateSection';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-admin-template-dashboard',
  templateUrl: './admin-template-dashboard.component.html',
  styleUrls: ['./admin-template-dashboard.component.css']
})
export class AdminTemplateDashboardComponent implements OnInit {
  template: any;
  templateSections: Observable<TemplateSection[]>;

  params: any;
  template_id: any;
  status: any;
  message: any;
  isUsed: any;

  constructor(private activatedRoute: ActivatedRoute, private templateService: TemplateService, private templateSectionService: TemplateSectionService) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.template_id = params['id']);
    this.template = this.templateService.getTemplate(this.template_id).subscribe(
      data => {
        this.template.title = data['title'];
        this.templateService.isUsed(this.template_id).subscribe(
          data => {
            if (data == 1) {
              this.template.isUsed = true
            }
          }
        )
        
      },
    error => console.log(<any>error));
    this.templateSections = this.templateSectionService.getTemplateSections(this.template_id);
  }

}
