<div class="jumbotron">
  <div class="display-4">
    <h1 class="display-4">{{registration.title}}</h1>
    <h2 class="display-6">{{event.title}}</h2>
  </div>
<img src="{{backendurl}}images/{{event.imageLink}}" alt="">
</div>

<div class="text-center">
  <div class="btn-group" role="group">
  <button type="button" class="btn btn-light" [class.active]="switch == 'uebersicht'" id="uebersicht" (click)="updateSwitch($event)">
    <span (click)="updateSwitch($event)" id="uebersicht" *ngIf="registration.type=='person'">Übersicht</span>
    <span (click)="updateSwitch($event)" id="uebersicht" *ngIf="registration.type=='group'">Gruppen</span>
  </button>
  <button *ngIf="registration.type == 'group'" type="button" class="btn btn-light" [class.active]="switch == 'teilnehmende'" id="teilnehmende" (click)="updateSwitch($event)">
    Teilnehmende
  </button>
  <button type="button" class="btn btn-light" [class.active]="switch == 'gen'" id="gen" (click)="updateSwitch($event)">Generierungen</button>
</div>
</div>
<div class="container">
  <form *ngIf="switch=='uebersicht' || switch=='teilnehmende' || switch=='anmeldende' || switch=='staves'">
    <div class="form-group">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Suchen ..." [(ngModel)]="filter" [ngModelOptions]="{standalone: true}">
      </div>
    </div>
  </form>
  <div *ngIf="switch=='uebersicht'">
    Gesamt: <b>{{total}}</b>
    <a [routerLink]="[]" (click)="copyEmail()" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-envelope-o"></i> E-Mail Adressen kopieren</a>
    <a [routerLink]="[]" (click)="export()" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-file-excel-o"></i> Export Excel</a>
    </div>
  <table *ngIf="switch=='uebersicht'" class="table table-bordered table-striped">

    <thead>
      <tr>
        <td></td>
        <td>Nachname</td>
        <td>Vorname</td>
        <td>Verband</td>
        <td *ngIf="registration.type == 'group'">Teilnehmende</td>
        <td *ngIf="registration.confirmation == true && registration.type != 'group'">Freigabe</td>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let subscription of subscriptions | async | filter:{nachname: filter, vorname: filter, verband: filter, gruppierung: filter}">
        <tr>
        <td>
      <!--    <button type="button" class="btn btn-link" *ngIf="subscription.expanded" (click)="subscription.expanded = false">-</button>
          <button type="button" class="btn btn-link" *ngIf="!subscription.expanded" (click)="subscription.expanded = true">+</button>-->
        </td>
        <td>{{ subscription.nachname }}</td>
        <td>{{ subscription.vorname }}</td>
        <td>{{ subscription.verband }}</td>
        <td *ngIf="registration.type == 'group'">{{ subscription.tnnumber }}</td>
        <td *ngIf="registration.confirmation == true && registration.type != 'group'">
          <button [disabled]="subscription.approved == 1" (click)="updateConfirmation(subscription.id, 1)" class="btn btn-success">freigeben</button>
          <button [disabled]="subscription.approved == 0" (click)="updateConfirmation(subscription.id, 0)" class="btn btn-danger mx-2">ablehnen</button>
        </td>
      </tr>

      </ng-container>
    </tbody>

  </table>
  <div *ngIf="switch=='teilnehmende'">
    Gesamt: <b>{{tnanzahl}}</b>
    <a [routerLink]="[]" (click)="exportTNs()" class="btn btn-default float-right" role="button" aria-expanded="false"><i class="fa fa-file-excel-o"></i> Export Excel</a>
    </div>
  <table *ngIf="switch=='teilnehmende'" class="table table-bordered table-striped">

    <thead>
      <tr>
        <td></td>
        <td>Nachname</td>
        <td>Vorname</td>
        <td *ngIf="registration.confirmation == true">Freigabe</td>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let tn of tns | async | filter:{fieldNachname: filter, fieldVorname: filter}">
        <tr>
        <td>
        </td>
        <td>{{ tn[fieldNachname] }}</td>
        <td>{{ tn[fieldVorname] }}</td>
        <td *ngIf="registration.confirmation == true">
          <button [disabled]="tn.approved == 1" (click)="updateConfirmation(tn.id, 1)" class="btn btn-success">freigeben</button>
          <button [disabled]="tn.approved == 0" (click)="updateConfirmation(tn.id, 0)" class="btn btn-danger mx-2">ablehnen</button>
        </td>
      </tr>

      </ng-container>
    </tbody>

  </table>



<div id="generierungen" *ngIf="switch=='gen'">
  <div class="card mx-auto">
    <div class="card-body text-left">
      <a [routerLink]="[]" (click)="bdkjdu()" class="btn btn-default" role="button" aria-expanded="false"><i class="fa fa-file-pdf-o"></i> Zuschusslisten generieren</a>
    </div>
  </div>
</div>

</div>
