import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import { Settings } from '../classes/settings';
import { Event } from '../models/event';
import { EventService } from '../services/event.service';
import { RegistrationService } from '../services/registration.service';


@Component({
  selector: 'app-events-dashboard',
  templateUrl: './events-dashboard.component.html',
  styleUrls: ['./events-dashboard.component.css']
})
export class EventsDashboardComponent implements OnInit {

  events: Observable<Event[]>;
  backendurl: any;
  constructor(
              private eventService: EventService,
              private registrationService: RegistrationService
  ) { }

  getMaxTNs(event) {
    this.registrationService.getRegistrations(event).subscribe(
      data => {
        return this.registrationService.getMaxTNs(data.id)
      }
    )

  }

  getCountTNs(event) {
    this.registrationService.getRegistrations(event).subscribe(
      data => {
        return this.registrationService.getTNsCount(data.id)
      }
    )
  }

  ngOnInit() {
    this.backendurl = Settings.appURL
    this.events = this.eventService.getActiveEvents();
  }

}
