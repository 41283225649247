import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TemplateService} from '../../../services/template.service';
import { Template } from '../../../models/template';
import { Router} from '@angular/router';


@Component({
  selector: 'app-admin-templates-edit',
  templateUrl: './admin-templates-edit.component.html',
  styleUrls: ['./admin-templates-edit.component.css']
})
export class AdminTemplatesEditComponent implements OnInit {

  params: any;
  template_id: any;
  template: any;
  status: any;
  message: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private templateService: TemplateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.template_id = params['id']);
   this.template = this.templateService.getTemplate(this.template_id).subscribe(
     data => {
       this.template.description = data['description'];
       this.template.title = data['title'];
       this.template.id = data['id'];
       this.template.active = data['active'];
       this.template.type = data['type'];
     },
     error => console.log(<any>error));
  }

  ngOnDestroy() {
   this.params.unsubscribe();
 }

 updateTemplate(template) {
   this.templateService.updateTemplate(template)
   .subscribe(
     template => {
       this.status = 'success';
       this.message = template['message'];
       this.router.navigate(['/admin/templates']);

     },
     error => {
       console.log(<any>error);
       this.status = 'error';
       this.message = error['message'];
     });
   }

}
