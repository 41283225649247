<div class="jumbotron row">
  <div class="display-4 col-12 col-sm-9">
      <h1 class="display-4">{{registration.title | uppercase}}</h1>
      <h2 class="display-6">{{event.title | uppercase}}</h2>
    </div>
    <div class="col-12 col-sm-3">
      <img class="img-fluid" src="{{backendurl}}images/{{event.imageLink}}" alt="">
    </div>  </div>
  <div class="alert alert-warning text-center" role="alert" *ngIf="closed == 1">
    Eine Anpassung ist leider nicht mehr möglich, weil der Anmeldeschluss erreicht wurde.
  </div>
<div class="col-md-10 col-md-offset-1">
  <div *ngIf="status=='success'" class="alert alert-success" role="alert"> {{ message }}</div>
  <div *ngIf="status=='error'" class="alert alert-danger" role="alert"> {{ message }} </div>
  <div class="well well bs-component">
      <fieldset>
        <div class="form-group">
          <label for="name" class="col-lg-2 control-label">Name</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="{{user.surname}} {{user.givenname}}" readonly>
          </div>
        </div>
        <div class="form-group">
          <label for="email" class="col-lg-2 control-label">E-Mail</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="{{user.email}}" readonly>
          </div>
        </div>
        <div class="form-group">
          <label for="verband" class="col-lg-2 control-label">Verband</label>
          <div class="col-lg-10">
           <input type="text" class="form-control" placeholder="{{verband?.title}}" readonly>
          </div>
        </div>
        <div *ngIf="subscription_id">

          <p>Deine Anmeldung:</p>
          <div class="col-md-12 col-md-offset-1">
            <div class="table-responsive">
              <table class="table table-users">
                <thead>
                  <tr>
                    <th>Nachname</th>
                    <th>Vorname</th>
                    <th *ngIf="registration.confirmation == 1">Status</th>
                    <th>Aktion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{user.givenname}}</td>
                    <td>{{user.surname}}</td>
                    <td *ngIf="registration.confirmation == 1">
                      <span *ngIf="subscription.approved == NULL">wartet</span>
                      <span *ngIf="subscription.approved == 1">freigegeben</span>
                      <span *ngIf="subscription.approved == 0">abgelehnt</span>
                    </td>
                    <td>
                     <a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription', 'edit']" class="btn btn-warning btn-users" *ngIf="event.active == 1 && registration.active == 1 && closed == 0"> Bearbeiten</a>
                     <a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription', 'del']" class="btn btn-danger btn-users mx-2" *ngIf="event.active == 1 && registration.active == 1 && closed == 0"> Löschen</a>
                   </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          </div>

      </fieldset>
  </div>
</div>
