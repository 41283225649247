import { Injectable } from '@angular/core';
import {throwError as observableThrowError, Observable} from 'rxjs';
import { BackendService } from '../services/backend.service';


@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private backendService: BackendService
  ) { }

    copyEmail(registration): Observable<any> {
        return this.backendService.get('events/registrations/' + registration + '/subscriptions/copyEmail')
          .map((response) => response);
        }

        copyEmailTNs(registration): Observable<any> {
            return this.backendService.get('events/registrations/' + registration + '/subscriptions/copyEmailTNs')
              .map((response) => response);
            }

  exportData(data: any, typ): void {
    const endung = typ.endsWith('pdf') ? '.pdf' : '.csv';
        const format = typ.endsWith('pdf') ? 'application/pdf' : 'text/csv;charset=utf-8;';
        const dateityp = typ.endsWith('pdf') ? 'pdf' : typ;

    this.backendService.download('export/' + typ, data, typ)
    .subscribe((res: any) => {
                let blob: Blob;
                let daten: string;

                if (typ.endsWith('pdf')) {
                    blob = new Blob([res], {
                        type: format
                    });
                } else {
                    daten = res.toString();
                    if (daten.trim() !== '') {
                        blob = new Blob(['\ufeff' + daten], {
                            type: format
                        });
                    }
                }

                    const link = document.createElement('a');
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    if (link.download !== undefined) {
                        link.setAttribute('href', URL.createObjectURL(blob));
                        link.setAttribute('download', "export" + endung);
                        link.click();
                    } else {
                        daten = format + ',' + daten;
                        window.open(encodeURI(daten));
                    }
                    document.body.removeChild(link);
            });


}

}
