import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EventService} from '../../../services/event.service';
import { Router} from '@angular/router';
import { Settings } from '../../../classes/settings';


@Component({
  selector: 'app-admin-event-edit',
  templateUrl: './admin-event-edit.component.html',
  styleUrls: ['./admin-event-edit.component.css']
})
export class AdminEventEditComponent implements OnInit {

  params: any;
  event_id: any;
  event: any;
  status: any;
  message: any;
  backendurl: any;
  filedata: any;

  fileEvent(e) {
    this.filedata = e.target.files[0];
  }
  

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private router: Router
  ) { }

  ngOnInit() {
    this.backendurl = Settings.appURL
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['id']);
   this.event = this.eventService.getEvent(this.event_id).subscribe(
     data => {
       this.event.description = data['description'];
       this.event.title = data['title'];
       this.event.imageLink = data['imageLink'];
       this.event.id = data['id'];
       this.event.active = data['active'];
     },
     error => console.log(<any>error));
  }

  ngOnDestroy() {
   this.params.unsubscribe();
 }

 updateEvent(event) {
   this.eventService.updateEvent(event)
   .subscribe(
    editevent => {
      if (this.filedata) {
        event.imageLink = ""
        var file = new FormData();
        file.append('imageLink', this.filedata)

 
      this.eventService.uploadEventImage(editevent["data"]["id"], file).subscribe(
        eventImage => {
                 this.status = 'success';
                this.message = editevent['message'];
                this.router.navigate(['/admin/events']);
        }
      )
    }
    else {
      this.status = 'success';
      this.message = editevent['message'];
      this.router.navigate(['/admin/events']);
    }

     },
     error => {
       console.log(<any>error);
       this.status = 'error';
       this.message = error['message'];
     });
   }


}

