import { Component, OnInit } from '@angular/core';
import {RegistrationService} from '../../../services/registration.service';
import {RegistrationTypeService} from '../../../services/registration-type.service';
import {TemplateService} from '../../../services/template.service';
import {ActivatedRoute, Router} from '@angular/router';
import { RegistrationType } from '../../../models/registrationType';
import { Template } from '../../../models/template';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-admin-event-registration-create',
  templateUrl: './admin-event-registration-create.component.html',
  styleUrls: ['./admin-event-registration-create.component.css']
})
export class AdminEventRegistrationCreateComponent implements OnInit {

  event_id: any;
  params: any;
  status: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private registrationTypeService: RegistrationTypeService,
    private templateService: TemplateService,
    private router: Router
  ) { }

  registrationTypes:  Observable<RegistrationType[]>;
  templatesGroup:  Array<Template>;
  templatesPerson:  Array<Template>;

  ngOnInit() {
    this.templatesGroup = [];
    this.templatesPerson = [];
    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
    this.registrationTypes = this.registrationTypeService.getRegistrationTypes();
    this.templateService.getTemplates().subscribe(
      templates => {
        console.log(templates)
        templates.forEach(template => {
          if (template.type == "person") {
            this.templatesPerson.push(template)
          }
          else if (template.type == "group") {
            this.templatesGroup.push(template)
          }            
          
        });
      }
    );
  }

  createRegistration(registration) {
       registration.event = this.event_id;
   this.registrationService.addRegistration(this.event_id, registration)
   .subscribe(
     registration => {
       this.router.navigate(['/admin/event', this.event_id, 'registrations']);
     },
     error => console.log(<any>error)
   );
 }
}
