import {Routes, RouterModule} from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdminEventCreateComponent } from './events/admin-event-create/admin-event-create.component';
import { AdminEventDeleteComponent } from './events/admin-event-delete/admin-event-delete.component';
import { AdminEventEditComponent } from './events/admin-event-edit/admin-event-edit.component';
import { AdminEventListComponent } from './events/admin-event-list/admin-event-list.component';
import { AdminEventRegistrationCreateComponent } from './events/admin-event-registration-create/admin-event-registration-create.component';
import { AdminEventRegistrationDeleteComponent } from './events/admin-event-registration-delete/admin-event-registration-delete.component';
import { AdminEventRegistrationEditComponent } from './events/admin-event-registration-edit/admin-event-registration-edit.component';
import { AdminEventRegistrationListComponent } from './events/admin-event-registration-list/admin-event-registration-list.component';
import { AdminEventRegistrationSubscriptionsListComponent } from './events/admin-event-registration-subscriptions-list/admin-event-registration-subscriptions-list.component';
import { AdminUserCreateComponent } from './users/admin-user-create/admin-user-create.component';
import { AdminUserEditComponent } from './users/admin-user-edit/admin-user-edit.component';
import { AdminUserDeleteComponent } from './users/admin-user-delete/admin-user-delete.component';
import { AdminUserListComponent } from './users/admin-user-list/admin-user-list.component';
import { AdminUserResetComponent } from './users/admin-user-reset/admin-user-reset.component';
import { AdminTemplateCopyComponent } from './templates/admin-template-copy/admin-template-copy.component';
import { AdminTemplatesCreateComponent } from './templates/admin-templates-create/admin-templates-create.component';
import { AdminTemplatesDeleteComponent } from './templates/admin-templates-delete/admin-templates-delete.component';
import { AdminTemplatesEditComponent } from './templates/admin-templates-edit/admin-templates-edit.component';
import { AdminTemplatesListComponent } from './templates/admin-templates-list/admin-templates-list.component';
import { AdminTemplateSectionCreateComponent } from './templates/section/admin-template-section-create/admin-template-section-create.component';
import { AdminTemplateSectionDeleteComponent } from './templates/section/admin-template-section-delete/admin-template-section-delete.component';
import { AdminTemplateSectionEditComponent } from './templates/section/admin-template-section-edit/admin-template-section-edit.component';
import { AdminTemplateFieldsCreateComponent } from './templates/section/fields/admin-template-fields-create/admin-template-fields-create.component';
import { AdminTemplateFieldsDeleteComponent } from './templates/section/fields/admin-template-fields-delete/admin-template-fields-delete.component';
import { AdminTemplateFieldsEditComponent } from './templates/section/fields/admin-template-fields-edit/admin-template-fields-edit.component';
import { AdminDashboardComponent } from './admin-dashboard.component';
import { AdminTemplateDashboardComponent } from './templates/admin-template-dashboard/admin-template-dashboard.component';


export const adminRoutes: Routes = [
  { path: '', component: AdminDashboardComponent},
  { path: 'event/create', component: AdminEventCreateComponent},
  { path: 'event/delete/:id', component: AdminEventDeleteComponent},
  { path: 'event/edit/:id', component: AdminEventEditComponent},
  { path: 'events', component: AdminEventListComponent},
  { path: 'event/:event_id/registration/create', component: AdminEventRegistrationCreateComponent},
  { path: 'event/:event_id/registration/delete/:id', component: AdminEventRegistrationDeleteComponent},
  { path: 'event/:event_id/registration/edit/:id', component: AdminEventRegistrationEditComponent},
  { path: 'event/:event_id/registrations', component: AdminEventRegistrationListComponent},
  { path: 'event/:event_id/registration/:registration_id/subscriptions', component: AdminEventRegistrationSubscriptionsListComponent},
  { path: 'user/create', component: AdminUserCreateComponent},
  { path: 'user/delete/:id', component: AdminUserDeleteComponent},
  { path: 'user/edit/:id', component: AdminUserEditComponent},
  { path: 'user/reset/:id', component: AdminUserResetComponent},
  { path: 'users', component: AdminUserListComponent},
  { path: 'template/create', component: AdminTemplatesCreateComponent},
  { path: 'template/delete/:id', component: AdminTemplatesDeleteComponent},
  { path: 'template/edit/:id', component: AdminTemplatesEditComponent},
  { path: 'templates', component: AdminTemplatesListComponent},
  { path: 'template/:id', component: AdminTemplateDashboardComponent},
  { path: 'template/:id/copy', component: AdminTemplateCopyComponent},
  { path: 'template/:template_id/section/create', component: AdminTemplateSectionCreateComponent},
  { path: 'template/:template_id/section/delete/:id', component: AdminTemplateSectionDeleteComponent},
  { path: 'template/:template_id/section/edit/:id', component: AdminTemplateSectionEditComponent},
  { path: 'template/:template_id/section/:section_id/field/create', component: AdminTemplateFieldsCreateComponent},
  { path: 'template/:template_id/section/:section_id/field/delete/:id', component: AdminTemplateFieldsDeleteComponent},
  { path: 'template/:template_id/section/:section_id/field/edit/:id', component: AdminTemplateFieldsEditComponent},
];
