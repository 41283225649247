import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { RegistrationService } from '../../../services/registration.service';
import { EventService } from '../../../services/event.service';
import { TemplateSectionService } from '../../../services/template-section.service';
import { SubscriptionService } from '../../../services/subscription.service';
import {TemplateFieldService} from '../../../services/template-field.service';
import { ExportService } from '../../../services/export.service';
import { NotifyService } from '../../../services/notify.service';
import { Settings } from '../../../classes/settings'

@Component({
  selector: 'app-admin-event-registration-subscriptions-list',
  templateUrl: './admin-event-registration-subscriptions-list.component.html',
  styleUrls: ['./admin-event-registration-subscriptions-list.component.css']
})

export class AdminEventRegistrationSubscriptionsListComponent implements OnInit {

  event_id: any;
  registration_id: any;
  params: any;
  event:  any;
  stamm:  any;
  bezirk:  any;
  registration: any;
  progress: any;
  user: any;
  templateSections: any;
  subscriptions: any;
  templateFields: any;
  templateSection_id: any;
  subscription: any;
  choices: any;
  switch: any;
  total: any;
  filter: any;
  exportData: any;
  bumms: any;
  backendurl: any;
  tns: any;
  tnanzahl: any;
  fieldVorname: any;
  fieldNachname: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private registrationService: RegistrationService,
    private subscriptionService: SubscriptionService,
    private templateSectionService: TemplateSectionService,
    private templateFieldService: TemplateFieldService,
    private exportService: ExportService,
    private notifyService: NotifyService,
  ) { }

  export() {
        this.subscriptions.subscribe((response) => {
          return this.exportService.exportData(response, 'csv');
        })
    }

    exportTNs() {
      this.tns.subscribe((response) => {
        return this.exportService.exportData(response, 'csv');
      })
  }

  copyEmail() {
    this.exportService.copyEmail(this.registration_id).subscribe(
      data =>{
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = data.data;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.notifyService.snotifySuccess("E-Mail Adressen in die Zwischenablage kopiert!")
      }
    )
  }

  updateConfirmation(subscriptionid, result){
    this.subscription = new Object
    this.subscription.id = subscriptionid;
    this.subscription.registration = this.registration_id
    this.subscription.result = result
    this.subscriptionService.setConfirmation(this.subscription).subscribe(
      data => {
        this.notifyService.snotifySuccess("Freigabe erfolgreich angepasst!")
        if (this.registration.type == "group") {
          this.tns = this.subscriptionService.getSubscriptionTNs(this.event_id, this.registration_id)
          this.tns.subscribe(
            result => {
              this.tnanzahl = result.length
            }
          )
        }
        else {

        }
        this.subscriptions = this.subscriptionService.getSubscriptions(this.event_id, this.registration_id)
        this.subscriptions.subscribe(
          result => {
            this.total = result.length
          }
  )
      },
      error => {
        this.notifyService.snotifyError("Es ist ein Fehler aufgetreten!")
        console.log(<any>error)
      }
    )
  }

      bdkjdu(){
        this.subscriptionService.rdp(this.event_id, this.registration_id)
            .subscribe((res: any) => {
                        let blob: Blob;
                        let daten: string;

                            blob = new Blob([res], {
                                type: 'apllication/pdf'
                            });

                     
                            const link = document.createElement('a');
                            link.style.display = 'none';
                            document.body.appendChild(link);
                            if (link.download !== undefined) {
                                link.setAttribute('href', URL.createObjectURL(blob));
                                link.setAttribute('download', "bdkjdu" + '.pdf');
                                link.click();
                            }
                            document.body.removeChild(link);
                    });
          }


            updateSwitch(event) {
                this.switch = event.target.id;
                }



    ngOnInit() {
      this.backendurl = Settings.appURL
      this.switch = "uebersicht";
      this.total = 0;

  this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
  this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['registration_id']);

  this.event = this.eventService.getEvent(this.event_id).subscribe(
    data => {
      this.event.title = data['title'];
      this.event.imageLink = data['imageLink'];
    },
    error => console.log(<any>error)
  );

  this.registration = this.registrationService.getRegistration(this.event_id, this.registration_id).subscribe(
    data => {
      this.registration.title = data['title'];
      this.registration.type = data['type'];
      this.registration.template = data['template'];
      this.registration.templateTN = data['templateTN'];
      this.registration.description = data['description'];
      this.registration.confirmation = data['confirmation'];

      if (this.registration.type == 'group') {
        this.tns = this.subscriptionService.getSubscriptionTNs(this.event_id, this.registration_id)
        this.tns.subscribe(
          result => {
            this.tnanzahl = result.length
          }
        )
        this.templateSectionService.getTemplateSections(this.registration.templateTN).subscribe(
          sections => {
            sections.forEach(
              section => {
                this.templateSection_id = section.id
                this.templateFieldService.getTemplateFields(section.id).subscribe(
                  fields => {
                    fields.forEach(
                      field => {
                        if(field.title == "Vorname") {
                          this.fieldVorname = section.id + "_" + field.id
                        }
                        else if(field.title == "Nachname") {
                          this.fieldNachname = section.id + "_" + field.id
                        } 
                        if(field.type == "option" || field.type == "choice" ) {
                          field.choices = JSON.parse(field.choices)
                        }
                      }
                    )
                    this.templateFields=fields
                  }
                )
  
              }
            )
          }
        )
      }
      else {
        this.templateSectionService.getTemplateSections(this.registration.template).subscribe(
          sections => {
            sections.forEach(
              section => {
                this.templateSection_id = section.id
                this.templateFieldService.getTemplateFields(section.id).subscribe(
                  fields => {
                    fields.forEach(
                      field => {
                        if(field.title == "Vorname") {
                          this.fieldVorname = section.id + "_" + field.id
                        }
                        else if(field.title == "Nachname") {
                          this.fieldNachname = section.id + "_" + field.id
                        } 
                        if(field.type == "option" || field.type == "choice" ) {
                          field.choices = JSON.parse(field.choices)
                        }
                      }
                    )
                    this.templateFields=fields
                  }
                )
  
              }
            )
          }
        )

      }

    },
    error => console.log(<any>error)
  );

  this.subscriptions = this.subscriptionService.getSubscriptions(this.event_id, this.registration_id)
  this.subscriptions.subscribe(
    result => {
      this.total = result.length
    }
  )

}
}
