<div class="jumbotron row">
    <div class="display-4 col-12 col-sm-9">
        <h1 class="display-4">{{registration.title | uppercase}}</h1>
        <h2 class="display-6">{{event.title | uppercase}}</h2>
      </div>
      <div class="col-12 col-sm-3">
        <img class="img-fluid" src="{{backendurl}}images/{{event.imageLink}}" alt="">
      </div>  </div>

  <div class="col-md-10 col-md-offset-1" *ngIf="dataLoaded | async">
    <div class="card my-2">
      <div class="card-header">Persönliche Daten</div>
      <div class="card-body">
            <div class="mx-auto">
                <div class="form-group">
                    <label for="Vorname" class="col-lg-6 control-label">Vorname</label>
                    <div class="col-lg-6">
                        <input min="0" type="text" class="form-control" id="vorname" name="vorname" 
                        value="{{tn[fieldVorname]}}" disabled>
                    </div>
                </div>
                <div class="form-group">
                    <label for="Nachname" class="col-lg-6 control-label">Nachname</label>
                    <div class="col-lg-6">
                        <input min="0" type="text" class="form-control" id="nachname" name="nachname" 
                        value="{{tn[fieldNachname]}}" disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

          
  <div class="alert alert-danger" role="alert">
  Bist du dir sicher, dass du diesen Eintrag löschen möchtest?<br />
  <a [routerLink]="['/events', event_id, 'registrations', registration_id, 'subscription']" class="btn btn-outline-danger">Nein</a>
  <button type="button" class="btn btn-danger mx-2" (click)="deleteSubscription()">Ja</button>
  </div>
  