import { Component, OnInit } from '@angular/core';
import {TemplateFieldService} from '../../../../../services/template-field.service';
import {TemplateService} from '../../../../../services/template.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Template } from '../../../../../models/template';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-admin-template-fields-create',
  templateUrl: './admin-template-fields-create.component.html',
  styleUrls: ['./admin-template-fields-create.component.css']
})
export class AdminTemplateFieldsCreateComponent implements OnInit {

  template_id: any;
  section_id: any;
  params: any;
  status: any;

  template:  Observable<Template>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private templateFieldService: TemplateFieldService,
    private templateService: TemplateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => {
      this.template_id = params['template_id'];
      this.section_id = params['section_id'];
    });
    this.template = this.templateService.getTemplate(this.template_id);
  }

  createTemplateField(templateField) {
       templateField.section = this.section_id;
   this.templateFieldService.addTemplateField(this.section_id, templateField)
   .subscribe(
     templateField => {
       this.router.navigate(['/admin/template', this.template_id]);
     },
     error => console.log(<any>error)
   );
 }

}
