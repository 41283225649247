import { Component, OnInit } from '@angular/core';
import {TemplateSectionService} from '../../../../services/template-section.service';
import {TemplateService} from '../../../../services/template.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Template } from '../../../../models/template';
import { TemplateSection } from '../../../../models/templateSection';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-template-section-create',
  templateUrl: './admin-template-section-create.component.html',
  styleUrls: ['./admin-template-section-create.component.css']
})
export class AdminTemplateSectionCreateComponent implements OnInit {

  template_id: any;
  params: any;
  status: any;

  template:  Observable<Template>;
  templateSections:  Observable<TemplateSection[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private templateSectionService: TemplateSectionService,
    private templateService: TemplateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.template_id = params['template_id']);
    this.template = this.templateService.getTemplate(this.template_id);
    this.templateSections = this.templateSectionService.getTemplateSections(this.template_id);
  }

  createTemplateSection(templateSection) {
       templateSection.template = this.template_id;
   this.templateSectionService.addTemplateSection(this.template_id, templateSection)
   .subscribe(
     templateSection => {
       this.router.navigate(['/admin/template', this.template_id]);
     },
     error => console.log(<any>error)
   );
 }

}
