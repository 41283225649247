import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { SubscriptionService } from '../../../../services/subscription.service';
import { NotifyService } from '../../../../services/notify.service';
import { RegistrationService } from '../../../../services/registration.service';
import { TemplateSectionService } from '../../../../services/template-section.service';
import { EventService } from '../../../../services/event.service';
import { Settings } from '../../../../classes/settings';
import { TemplateFieldService } from '../../../../services/template-field.service';

@Component({
  selector: 'app-events-registration-subscription-group-del-tn',
  templateUrl: './events-registration-subscription-group-del-tn.component.html',
  styleUrls: ['./events-registration-subscription-group-del-tn.component.css']
})
export class EventsRegistrationSubscriptionGroupDelTNComponent implements OnInit {
    id: any;
    params: any;
    event_id: any;
    registration_id: any;
    templateSection_id: any;
    subscription_id: any;
    event: any;
    templateSection: any;
    registration: any;
    backendurl: any;
    tn_id: any;
    fieldVorname: any;
    fieldNachname: any
    tn: any;
    dataLoaded: Promise<boolean>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private notifyService: NotifyService,
    private eventService: EventService,
    private registrationService: RegistrationService,
    private templateSectionService: TemplateSectionService,
    private router: Router,
    private templateFieldService: TemplateFieldService
  ) { }

  deleteSubscription() {
    this.subscriptionService.deleteSubscriptionTN(this.event_id, this.registration_id, this.tn_id).subscribe(
      data => {
        this.notifyService.snotifySuccess("Anmeldung erfolgreich gelöscht!")
        this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/']);
      },
      error => {
        this.notifyService.snotifyError("Beim Löschen der Anmeldung ist ein Fehler aufgetreten!")
        console.log(<any>error);
      });
    }

  ngOnInit() {
      this.backendurl = Settings.appURL
      this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
      this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['registration_id']);
      this.params = this.activatedRoute.params.subscribe(params => this.tn_id = params['tn_id']);




      this.event = this.eventService.getEvent(this.event_id).subscribe(
        data => {
          this.event.title = data['title'];
          this.event.imageLink = data['imageLink'];
          this.event.active = data['active'];

          if(this.event.active == 0) {
            this.notifyService.snotifyError("Die Anmeldung ist deaktiviert!")
            this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/' + this.templateSection_id]);
          }
        },
        error => console.log(<any>error)
      );

      this.registration = this.registrationService.getRegistration(this.event_id, this.registration_id).subscribe(
        data => {
          this.registration.title = data['title'];
          this.registration.template = data['template'];
          this.registration.templateTN = data['templateTN'];
          this.registration.description = data['description'];
          this.registration.active = data['active'];

          this.registrationService.isRegistrationClosed(this.event_id, this.registration_id).subscribe(
            data => {
              if (data == true) {
                this.notifyService.snotifyError("Die Anmeldung ist deaktiviert!")
                this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/' + this.templateSection_id]);
              }
            }
          )

          if(this.registration.active == 0) {
            this.notifyService.snotifyError("Die Anmeldung ist deaktiviert!")
            this.router.navigate(['/events/' + this.event_id + '/registrations/' + this.registration_id + '/subscription/' + this.templateSection_id]);
          }

          this.templateSectionService.getTemplateSections(this.registration.templateTN).subscribe(
            sections => {
                let section = sections[0]
                this.templateFieldService.getTemplateFields(section.id).subscribe(
                  fields => {
                    fields.forEach(
                      field => {
                        if(field.title == "Vorname") {
                          this.fieldVorname = section.id + "_" + field.id
                        }
                        else if(field.title == "Nachname") {
                          this.fieldNachname = section.id + "_" + field.id
                        } 
                      }
                    )
                    this.subscriptionService.getSubscriptionTN(this.event_id, this.registration_id, this.tn_id).subscribe(
                      data => {
                        this.tn = data;
                        this.dataLoaded = Promise.resolve(true)
                      })
                  }
                  
                )
          }
          )

        },
        error => console.log(<any>error)
      );

     
      
    }

      ngOnDestroy() {
        this.params.unsubscribe();
      }

}