import { Component, OnInit, Input } from '@angular/core';
import {TemplateFieldService} from '../../../../services/template-field.service';
import { Subscription } from '../../../../models/subscription';
import {ActivatedRoute} from '@angular/router';
import { TemplateSectionService } from '../../../../services/template-section.service';
import { UserService } from '../../../../services/user.service';
import { SubscriptionService } from '../../../../services/subscription.service';
import { Router} from '@angular/router';
import { NotifyService } from '../../../../services/notify.service';
import { RegistrationService } from '../../../../services/registration.service';
import { EventService } from '../../../../services/event.service';
import { Settings } from '../../../../classes/settings';

@Component({
  selector: 'app-events-registration-subscription-group-edit-tn',
  templateUrl: './events-registration-subscription-group-edit-tn.component.html',
  styleUrls: ['./events-registration-subscription-group-edit-tn.component.css']
})
export class EventsRegistrationSubscriptionGroupEditTNComponent implements OnInit {
  templateFields: any;
  params: any;
  event_id: any;
  registration_id: any;
  section_id: any;
  templatePages: any;
  user: any;
  subscription: any;
  newsubscription: any;
  event: any;
  registration: any;
  choices: any;
  sections: any;
  closed: any;
  subscription_id: any;
  tns: any;
  backendurl: any;
  tn_id: any;

  constructor(
    private eventService: EventService,
    private registrationService: RegistrationService,
    private templateFieldService: TemplateFieldService,
    private activatedRoute: ActivatedRoute,
    private templateSectionService: TemplateSectionService,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private notifyService: NotifyService
  ) { }

  UpdateSubscription(subscriptiondata) {
    this.subscription = new Subscription()
    this.subscription.data = subscriptiondata
    this.subscription.registration = this.registration_id
    this.subscription.user = this.user.id
  
      this.subscription.id = this.subscription_id
      this.subscriptionService.updateSubscriptionTN(this.event_id, this.registration_id, this.subscription)
        .subscribe(
          subscription => {
            this.notifyService.snotifySuccess("Anmeldung erfolgreich gespeichert!")
            this.router.navigate(['/events', this.event_id, 'registrations', this.registration_id, 'subscription'])
            .then(() => {
              window.location.reload();
            });
          },
          error => {
            this.notifyService.snotifyError("Anmeldung fehlerhaft!")
          }
        )
    } 
  

  updateField(section, field, option, event) {
    for (var i = 0; i < this.templateFields[section].length; i++){
     if (this.templateFields[section][i].id == field){
       if(this.templateFields[section][i].type == "option" || this.templateFields[section][i].type == "choice" ) {
         for (var j = 0; j < this.templateFields[section][i].choices.length; j++){
            if (this.templateFields[section][i].choices[j].name == option){
              this.templateFields[section][i].choices[j].selected = event.target.checked;
              this.subscription.data[this.templateFields[section][i].section + "_" + this.templateFields[section][i].id] = JSON.stringify(this.templateFields[section][i].choices)
            }
          }
       }
       else {
         this.subscription.data[this.templateFields[section][i].section + "_" + this.templateFields[section][i].id] = event.target.value
       }
     }
    }
  }

  ngOnInit() {
    this.backendurl = Settings.appURL
    this.closed=0;

    this.params = this.activatedRoute.params.subscribe(params => this.event_id = params['event_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.registration_id = params['registration_id']);
    this.params = this.activatedRoute.params.subscribe(params => this.tn_id = params['tn_id']);

    this.templateFields=Array()

    this.event = this.eventService.getEvent(this.event_id).subscribe(
      data => {
        this.event.title = data['title'];
        this.event.imageLink = data['imageLink'];
        this.event.active = data['active'];
      },
      error => console.log(<any>error)
    );

    this.registration = this.registrationService.getRegistration(this.event_id, this.registration_id).subscribe(
      data => {
        this.newsubscription = new Subscription()
        this.newsubscription.data = {}
        this.registration.title = data['title'];
        this.registration.template = data['template'];
        this.registration.templateTN = data['templateTN'];
        this.registration.description = data['description'];

        this.templateSectionService.getTemplateSections(this.registration.templateTN).subscribe(
          sections => {
            this.sections=sections
            sections.forEach(
              section => {
                this.section_id = section.id
                this.templateFieldService.getTemplateFields(section.id).subscribe(
                  fields => {
                    fields.forEach(
                      field => {
                        if(field.type == "option" || field.type == "choice" ) {
                          field.choices = JSON.parse(field.choices)
                          this.newsubscription.data[field.section + "_" + field.id] = JSON.stringify(field.choices)
                        }
                        else {
                          this.newsubscription.data[field.section + "_" + field.id] = ""
                        }
                      }
                    )
                    this.templateFields[section.id]=fields
                  }
                )

              }
            )
          }
        )
        this.registrationService.isRegistrationClosed(this.event_id, this.registration_id).subscribe(
          data => {
            if (data === true) {
            this.closed=1;
            }
          }
        )
      },
      error => console.log(<any>error)
    );

    this.user = this.userService.getActualUser().subscribe(
      user => {
      this.user = user;
      this.user.id = this.user['id'];
       this.user.surname = this.user['surname'];
       this.user.givenname = this.user['givenname'];
       this.user.email = this.user['email'];
       this.user.stamm = this.user['stamm'];

       this.subscription = this.subscriptionService.getSubscriptionTN(this.event_id, this.registration_id, this.tn_id).subscribe(
        data => {
          this.subscription = data;
          this.subscription_id = this.subscription['id'];  
        },
        error => {
          console.log(<any>error)
        }
      );

    });

  }

}