<div class="jumbotron">
  <h1 class="display-4">Veranstaltungen</h1>
</div>
<div class="col-md-10 col-md-offset-1">
  <div>
    <a [routerLink]="['/admin/event/create']" class="btn btn-success">Neue Veranstaltung anlegen</a>
    <div class="form-check float-right">
      <input type="checkbox" class="form-check-input" (change)="loadAll($event)"> 
      <label class="form-check-label" for="defaultCheck1">
        Inaktive Veranstaltungen einblenden
      </label>
    </div>
  </div>
</div>
<br />
<div class="col-md-12 col-md-offset-1">
  <div class="form-group">
    <div class="input-group row">
      <input type="text" class="form-control" placeholder="Suchen ..." [(ngModel)]="filter" [ngModelOptions]="{standalone: true}">
    </div>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Logo</th>
          <th>Veranstaltung</th>
          <th>Aktion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of events | async | filter:{title: filter}">
          <td><img src="{{backendurl}}images/{{event.imageLink}}" width="200"/></td>
          <td>{{event.title}} </td>
          <td>
            <a [routerLink]="['/admin/event/', event.id, 'registrations']" class="btn btn-info"> <i class="fa fa-eye"></i></a>
            <a [routerLink]="['/admin/event/edit', event.id]" class="btn btn-warning mx-2"> <i class="fa fa-pencil"></i></a>
            <a [routerLink]="['/admin/event/delete', event.id]" class="btn btn-danger"> <i class="fa fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
