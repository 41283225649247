import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TemplateSectionService} from '../../../../services/template-section.service';


@Component({
  selector: 'app-admin-template-section-delete',
  templateUrl: './admin-template-section-delete.component.html',
  styleUrls: ['./admin-template-section-delete.component.css']
})
export class AdminTemplateSectionDeleteComponent implements OnInit {

  id: any;
  template_id: any;
   params: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private templateSectionService: TemplateSectionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.params = this.activatedRoute.params.subscribe(params => this.id = params['id']);
    this.params = this.activatedRoute.params.subscribe(params => this.template_id = params['template_id']);
      this.templateSectionService.deleteTemplateSection(this.template_id, this.id).subscribe(
        data => {
          this.router.navigate(['/admin/template/', this.template_id]);
        },
        error => console.log(<any>error));
      }

      ngOnDestroy() {
        this.params.unsubscribe();
      }

}
