
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TemplateSection } from '../models/templateSection';
import { BackendService } from './backend.service';


@Injectable()
export class TemplateSectionService {

  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) { }

  addTemplateSection(template_id: string, TemplateSection: Object): Observable<TemplateSection[]> {
    return this.backendService.post('templates/' + template_id + '/templateSections', TemplateSection)
       .map((response) => response)
      .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
    }

  getTemplateSections(template_id: string): Observable<any>{
    return this.backendService.get('templates/'+ template_id + '/templateSections')
       .map((response) => response);
  }

  getTemplateSection(section_id: string, template_id: string): Observable<any>{
    const apiUrl = 'templates';
    const url = `${apiUrl}/${template_id}/templateSections/${section_id}`;

   return this.backendService.get(url)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

  updateTemplateSection(template: Object, template_id: string): Observable<TemplateSection[]> {
   const apiUrl = 'templates';
   const url = `${apiUrl}/${template_id}/templateSections/${template['id']}`;

   return this.backendService.put(url, template)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

  deleteTemplateSection(template: string, template_id: string): Observable<any> {
   const apiUrl = 'templates';
   const url = `${apiUrl}/${template_id}/templateSections/${template}`;
   return this.backendService.delete(url)
      .map((response) => response)
     .catch((error: any) => observableThrowError(error.json().error || {message: 'Server Error'} ));
  }

}
