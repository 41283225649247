import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { StammService } from '../services/stamm.service';
import { Stamm } from '../models/stamm';
import { VerbandService } from '../services/verband.service'; 
import { Verband } from '../models/verband';
import { UserService } from '../services/user.service';
import {Observable} from 'rxjs';
import {NotifyService} from '../services/notify.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

   verbandSelected: any = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private verbandService: VerbandService,
    private stammService: StammService,
    private userService: UserService,
    private router: Router,
    private notifyService: NotifyService
  ) { }

  staemme:  Observable<Stamm[]>;
  verbaende:  Observable<Verband[]>;
  checkPassword: boolean
  status: any;
  message: any;
  angular: any;

  ngOnInit() {
    this.verbaende = this.verbandService.getVerbaende()
  }
  createUser(user) {
    user.admin = 0

      this.userService.addUser(user)
      .subscribe(
        user => {
          this.notifyService.snotifySuccess("Registrierung erfolgreich")
          this.router.navigate(['/login']);
        },
        error => {
          console.log(<any>error)
          this.notifyService.snotifyError(error['message'])
      }
      );
    }

}
